import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "elbow"
    }}>{`Elbow`}</h2>
    <ul>
      <li parentName="ul">{`Biceps tendon ruptures`}</li>
      <li parentName="ul">{`Ulnar collateral ligament injuries`}<ul parentName="li">
          <li parentName="ul">{`Direct repair`}</li>
          <li parentName="ul">{`UCL reconstruction`}</li>
        </ul></li>
      <li parentName="ul">{`Medial and lateral epicondylitis`}</li>
      <li parentName="ul">{`Loose bodies in the elbow`}</li>
      <li parentName="ul">{`Ulnar neuritis`}</li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      